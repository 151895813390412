import App from '../application.js'

import './setup_i18n'
import '../vendor/bootstrap-datepicker/locales/bootstrap-datepicker.en.js'
import './translations.tc.en.js'

$.fn.datepicker.defaults.language = 'en'

I18n.defaultLocale = 'en'
I18n.locale = 'en'

export default App
